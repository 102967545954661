import React, { useState } from "react";
import "./About.css";

const FAQAccordion = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="accordion-wrapper">
      <div 
        className={`accordion-question ${isOpen ? 'open' : ''}`} 
        onClick={() => setIsOpen(!isOpen)}
      >
        {question}
        <span className="accordion-icon">{isOpen ? "▲" : "▼"}</span>
      </div>
      {isOpen && <div className="accordion-answer">{answer}</div>}
    </div>
  );
};

const About = () => {
  return (
    <div className="about-page">
      <h1 className="about-page-title">How It Works</h1>
      <div className="hero-image">
        <img src="/demo.png" alt="Koala reading a book" />
      </div>
      <p>
        Koala Notes is a free tool that allows you to export your Kobo highlights. It is a simple and easy-to-use tool that does not require
        any installation.
      </p>
      <h1 className="about-page-title">Frequently Asked Questions</h1>
      <FAQAccordion
        question="Will my data be stored?"
        answer="No, Koala Notes does not store any of your personal data or highlights. The tool processes your highlights locally in your browser and does not send any information to any server."
      />
      <FAQAccordion
        question="How do I export my highlights?"
        answer="To export your highlights, connect your Kobo device to your computer, locate the '.kobo' folder, find the 'KoboReader.sqlite' file, and upload it to Koala Notes. The tool will then process your highlights and allow you to copy them."
      />
      <FAQAccordion
        question="Is Koala Notes affiliated with Kobo?"
        answer="No, Koala Notes is not affiliated with Kobo. It is a free tool created by a Kobo user for Kobo users."
      />
    </div>
  );
};

export default About;
