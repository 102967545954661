import React from 'react';
import './TermsOfService.css';

const TermsOfService = () => {
  return (
    <div className="terms-of-service">
      <h1>Terms of Service</h1>
      <p><strong>Last Updated: 28 Sep 2024</strong></p>
      <p>Welcome to Koala Notes ("we," "us," or "our"). These Terms and Conditions ("Terms") govern your use of the Koala Notes website (the "Service"), which provides tools for extracting highlights and annotations from Kobo eReader files locally on your device without communication with any server or cloud service. By accessing or using the Service, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, you may not use the Service.</p>

      <h2>1. No Affiliation with Kobo or Other eReader Platforms</h2>
      <p>Koala Notes is an independent service and is not affiliated with, sponsored by, or endorsed by Kobo, Rakuten, or any other eReader platform. All trademarks, logos, and brand names mentioned in relation to ebook platforms (including but not limited to Kobo) are the property of their respective owners. Use of the Service does not imply any relationship or endorsement between Koala Notes and any eReader platform.</p>

      <h2>2. User Responsibility and Compliance with Copyright Laws</h2>
      <p>By using the Service, you agree that:</p>
      <ul>
        <li>You will only extract highlights or annotations from ebooks that you either own or have the legal right to access, including any ebooks borrowed from libraries or other sources.</li>
        <li>It is your sole responsibility to comply with all applicable copyright laws and the terms and conditions of any ebook provider.</li>
        <li>You agree not to use the Service for any illegal purposes, including but not limited to the unlawful reproduction, distribution, or public display of copyrighted materials.</li>
      </ul>
      <p><strong>User Warranty</strong>: By using the Service, you warrant that you have the legal right to access the ebook content from which you are extracting highlights or annotations. You agree that the responsibility for determining whether the content you access is legally permissible rests solely with you.</p>

      <h2>3. Prohibition Against Copyright Infringement</h2>
      <p>The Service is provided solely for personal, non-commercial use. You may not use the Service to:</p>
      <ul>
        <li>Distribute, share, or make available any content (including ebooks or highlights) to third parties without the permission of the copyright holder.</li>
        <li>Extract entire ebooks or significant portions of copyrighted content for any purpose that could violate copyright laws or infringe on intellectual property rights.</li>
      </ul>
      <p>We expressly prohibit the use of the Service to infringe upon the intellectual property rights of others, including the unlawful reproduction of entire books or substantial sections of an ebook through highlighting.</p>

      <h2>4. Personal Use Only</h2>
      <p>The Service is intended for personal, non-commercial use. By using the Service, you agree that:</p>
      <ul>
        <li>You will use the extracted highlights or annotations solely for personal purposes, such as note-taking, research, or studying.</li>
        <li>You will not use the Service to create derivative works, republish content, or redistribute ebook highlights in any way that could violate the terms of service of any ebook provider or copyright holder.</li>
      </ul>

      <h2>5. No Storage or Cloud Access</h2>
      <p>The Service operates entirely in your device's local environment, without communicating with any servers or storing any data in the cloud. As such:</p>
      <ul>
        <li>We do not collect, store, or have access to any data or content you process through the Service, including your ebooks or extracted highlights.</li>
        <li>Any data generated or processed by the Service remains solely on your device, and you are responsible for how that data is stored, used, or shared.</li>
      </ul>
      <p>We have no ability to monitor or control the content you upload or extract using the Service. Your use of the Service is entirely at your own discretion and risk.</p>

      <h2>6. Disclaimer of Warranties</h2>
      <p>The Service is provided on an "as-is" and "as-available" basis. To the fullest extent permitted by law, we disclaim all warranties, whether express or implied, including but not limited to:</p>
      <ul>
        <li>Any warranties of merchantability, fitness for a particular purpose, or non-infringement.</li>
        <li>Any warranties that the Service will meet your requirements, will be uninterrupted, error-free, or that any errors will be corrected.</li>
      </ul>
      <p>You use the Service at your own risk, and we make no representations or warranties regarding the legality of your use of the Service, including whether your use complies with applicable copyright laws.</p>

      <h2>7. Limitation of Liability</h2>
      <p>To the fullest extent permitted by law, in no event will we be liable to you or any third party for any direct, indirect, incidental, special, punitive, or consequential damages arising out of or in connection with:</p>
      <ul>
        <li>Your use of the Service.</li>
        <li>Your inability to use the Service.</li>
        <li>Any violation of copyright laws or intellectual property rights that occurs as a result of your use of the Service.</li>
      </ul>
      <p>This limitation of liability applies even if we have been advised of the possibility of such damages and whether the claim is based on warranty, contract, tort, or any other legal theory.</p>

      <h2>8. Indemnification</h2>
      <p>You agree to indemnify, defend, and hold us harmless from any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) that arise from your:</p>
      <ul>
        <li>Violation of these Terms.</li>
        <li>Violation of any third-party rights, including intellectual property or privacy rights.</li>
        <li>Use of the Service in any manner that violates applicable laws, including but not limited to copyright law.</li>
      </ul>

      <h2>9. User Acknowledgement</h2>
      <p>By using the Service, you acknowledge and agree that:</p>
      <ul>
        <li>You are solely responsible for the legality of the content you extract from ebooks and the manner in which you use the extracted content.</li>
        <li>You are aware of the potential legal risks associated with extracting and using highlights from ebooks that you do not own.</li>
      </ul>
      <p>We are not responsible for any legal consequences that arise from your use of the Service, including any legal actions taken against you for copyright infringement.</p>

      <h2>10. Changes to These Terms</h2>
      <p>We reserve the right to update or modify these Terms at any time without prior notice. Any changes will be effective immediately upon posting on this page. Your continued use of the Service following any changes constitutes your acceptance of the revised Terms.</p>

      <h2>11. Governing Law</h2>
      <p>These Terms are governed by and construed in accordance with the laws of Singapore, without regard to its conflict of law provisions. Any legal action or proceeding arising out of or related to these Terms shall be brought exclusively in the courts located in Singapore.</p>

      <h2>12. Contact Us</h2>
      <p>If you have any questions about these Terms, please contact us at awks888@outlook.com.</p>
    </div>
  );
};

export default TermsOfService;