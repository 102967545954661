import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import initSqlJs from "sql.js";
import { Buffer } from "buffer";
import "../App.css";
import "./Home.css";
import CodeHighlight from "./CodeHighlight/CodeHighlight";
import NotesContainer from "./NotesContainer/NotesContainer";
window.Buffer = window.Buffer || Buffer;

function Home() {
  const [db, setDb] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [selectedBook, setSelectedBook] = useState(null);
  const [highlights, setHighlights] = useState([]);
  const [showHighlights, setShowHighlights] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // function to handle the file drop
  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      if (!file.name.toLowerCase().endsWith(".sqlite")) {
        setErrorMessage("Please drop a .sqlite file!");
        return;
      }
      setErrorMessage(null);
      const SQL = await initSqlJs({
        locateFile: (file) => `${process.env.PUBLIC_URL}/sql-wasm.wasm`,
      });
      const arrayBuffer = await file.arrayBuffer();
      const database = new SQL.Database(new Uint8Array(arrayBuffer));
      setDb(database);
      setFileName(file.name);
    }
  }, []);

  // function to get the root props for the dropzone
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="home">
      {showHighlights ? <div className="popup-overlay">
          <div className="popup-content">
            <button className="close-popup" onClick={() => setShowHighlights(false)}>
              &times;
            </button>
            <NotesContainer db={db} />
          </div>
        </div>
      : (
        <main>
           <p className="tagline">
            Export your Kobo highlights for free, no install required!
          </p>
          <div className="hero-image">
            <img src="/demo gif.gif" alt="Demo of Koala Notes" />
          </div>
         

          <section className="instructions">
            <h2>3 simple steps:</h2>
            <ol>
              <li>Connect your Kobo to your computer via USB</li>
              <li>
                Find your Kobo Reader folder and show all hidden folders
                <ul>
                  <li>
                    <img
                      src="/apple-logo.png"
                      alt="Apple logo"
                      className="os-logo"
                    />
                    To see hidden folders on Mac, press Command + Shift + .
                    (period)
                  </li>
                  <li>
                    <img
                      src="/windows-logo.png"
                      alt="Windows logo"
                      className="os-logo"
                    />
                    To see hidden folders on Windows, press Control + Shift + .
                    (period)
                  </li>
                </ul>
              </li>
              <li>
                Find the folder titled <CodeHighlight>.kobo</CodeHighlight> and
                upload the <CodeHighlight>KoboReader.sqlite</CodeHighlight> file
                below!
              </li>
            </ol>
          </section>

          <div
            {...getRootProps()}
            className={`dropzone ${isDragActive ? "active" : ""}`}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the SQLite file here ...</p>
            ) : fileName ? (
              <p>
                Uploaded file: <CodeHighlight>{fileName}</CodeHighlight>
              </p>
            ) : (
              <div>
              <p>
                Drag 'n' drop your{" "}
                <CodeHighlight>KoboReader.sqlite</CodeHighlight> file here
              </p>
              <p className="disclaimer">(Koala Notes will not store your data, all processing is done inside your browser)</p>
              </div>
            )}
          </div>
          {db && <button onClick={() => setShowHighlights(true)} className="show-highlights">Show Your Highlights!</button>}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </main>
      )}
    </div>
  );
}

export default Home;
