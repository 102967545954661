import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
      <footer>
        <div className="footer-content">
          <nav className="footer-nav">
            <a href="/privacy">Privacy Policy</a>
            <a href="/terms">Terms of Service</a>
            <a href="/contact">Contact Us</a>
          </nav>
          <div className="copyright">
            © 2024 Koala Notes. All rights reserved.
          </div>
        </div>
      </footer>
    );
};

export default Footer;