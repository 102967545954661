import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p><strong>Last Updated: 28 Sep 2024</strong></p>

      <h2>1. Data We Collect</h2>
      <h3>No Personal Data Collection</h3>
      <p>We do not collect, store, or process any personal information through our website. Any data or content processed by the Service (such as ebook highlights or annotations) is handled entirely on your device, and no data is transmitted to any servers or third-party services.</p>
      <h3>Local Processing Only</h3>
      <p>All data related to the extraction of highlights or annotations from your ebooks is processed locally on your device. Koala Notes has no access to your ebook content, your highlights, or any other personal information.</p>

      <h2>2. Use of Third-Party Services</h2>
      <p>At present, Koala Notes does not use any third-party services that collect, store, or process user data. Should this change in the future, we will update this Privacy Policy accordingly and provide details of any third-party services involved.</p>

      <h2>3. Cookies and Tracking Technologies</h2>
      <p>We do not use cookies or any other tracking technologies to monitor or collect information about your use of the Service. Your use of the website is not tracked, and no browsing data is collected or shared with third parties.</p>

      <h2>4. User Responsibility for Data</h2>
      <p>Since we do not store or collect any data, you are responsible for managing any data or content that is generated or extracted using the Service. This includes ensuring that any ebook content you process complies with applicable copyright laws and is used for personal purposes only.</p>

      <h2>5. Data Security</h2>
      <p>Because the Service does not collect or store any personal data, there is no risk of personal data breaches or unauthorized access through our website. Any data processed is contained entirely on your device and is not accessible by Koala Notes.</p>

      <h2>6. Your Privacy Rights</h2>
      <p>Because no personal information is collected, stored, or processed, the rights granted under data protection laws (such as the GDPR or CCPA) regarding the access, correction, or deletion of personal data are not applicable to our Service. Should this policy change in the future, we will notify you and provide the means to exercise your rights under applicable laws.</p>

      <h2>7. Changes to This Privacy Policy</h2>
      <p>We reserve the right to update or modify this Privacy Policy at any time without prior notice. Any changes will be effective immediately upon posting on this page. Your continued use of the Service following any changes constitutes your acceptance of the revised Privacy Policy.</p>

      <h2>8. Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy, please contact us at awks888@outlook.com.</p>
    </div>
  );
};

export default PrivacyPolicy;