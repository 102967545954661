import React, { useState, useEffect, useRef } from "react";
import "./HighlightsList.css";
import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import HighlightCard from "./HighlightCard/HighlightCard";

const HighlightsList = ({ highlights, selectedBook, sortOrder, setSortOrder }) => {
	const highlightsContainerRef = useRef(null);
	const [copyAllText, setCopyAllText] = useState("Copy All");

	// function to scroll to the top of the highlights list when the sort order changes
	useEffect(() => {
		if (highlightsContainerRef.current) {
			highlightsContainerRef.current.scrollTop = 0;
		}
	}, [sortOrder]);


	// function to change the sort order
	const handleSortChange = () => {
		setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
	};

	// Filter out highlights with null or empty text
	const validHighlights = highlights.filter(highlight => highlight[2] && highlight[2].trim() !== '');

	// function to copy the highlights to the clipboard
	const copyHighlights = () => {
		if (validHighlights.length > 0) {
			const highlightTexts = validHighlights.map((highlight) => highlight[2]); // Assuming the text is at index 2
			const formattedHighlights = highlightTexts.join("\n\n");
			navigator.clipboard
				.writeText(formattedHighlights)
				.then(() => {
					setCopyAllText("Copied!");
					setTimeout(() => setCopyAllText("Copy All"), 2000); // Reset after 2 seconds
				})
				.catch((err) => {
					console.error("Failed to copy highlights: ", err);
					setCopyAllText("Error");
					setTimeout(() => setCopyAllText("Copy All"), 2000); // Reset after 2 seconds
				});
		}
	};

	return (
		<div className="highlights-list">
			<div className="highlights-header">
				<div className="title-and-count">
					<h2>
						{selectedBook}{" "}
						<small className="highlight-count">
							({validHighlights.length} highlights)
						</small>
					</h2>
				</div>
				{validHighlights.length > 0 && (
					<div className="header-buttons">
						<button onClick={copyHighlights}>{copyAllText}</button>
						<button
							onClick={handleSortChange}
							className={`sort-button ${sortOrder}`}
							aria-label={`Sort ${
								sortOrder === "asc" ? "Descending" : "Ascending"
							}`}
						>
							{sortOrder === "asc" ? <FaSortAmountDown /> : <FaSortAmountUp />}
							<span>
								{sortOrder === "asc" ? "Earliest First" : "Latest First"}
							</span>
						</button>
					</div>
				)}
			</div>
			<div className="highlights-scroll" ref={highlightsContainerRef}>
				{validHighlights.length > 0 ? (
					validHighlights.map((highlight, index) => (
						<HighlightCard key={index} highlight={highlight} />
					))
				) : (
					<div className="empty-state">
						<p>No highlights found for this book.</p>
						<p>Highlights might be empty or contain only whitespace.</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default HighlightsList;
