import React, { useState } from 'react';
import './HighlightCard.css';
import { FaCopy } from 'react-icons/fa';

function HighlightCard({ highlight }) {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    const textToCopy = highlight[2] + (highlight[3] ? `\n\nAnnotation: ${highlight[3]}` : '');
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="highlight-card">
      <p className="highlight-text">{highlight[2]}</p>
      {highlight[3] && (
        <div className="annotation-container">
          <div className="annotation-bubble">
            <p className="annotation-text">{highlight[3]}</p>
          </div>
        </div>
      )}
      <button
        onClick={copyToClipboard}
        className="copy-button"
        aria-label="Copy highlight"
      >
        <FaCopy />
        <span className="copy-tooltip">{copied ? 'Copied!' : 'Copy'}</span>
      </button>
    </div>
  );
}

export default HighlightCard;