import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import FAQ from './pages/FAQ';
import Legal from './pages/TermsOfService';
import About from './pages/About';
import Home from './components/Home';
import Footer from './components/Footer/Footer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Contact from './pages/Contact';      
function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <header className="nav-bar">
          <div className="logo-container">
            <Link to="/" className="logo-link">
              <img
                src="/kobokoala_transparent.png"
                alt="Koala Notes Logo"
                className="logo"
              />
              <h1 className="site-title">Koala Notes</h1>
            </Link>
          </div>
          <nav>
            <ul>
              <li className="nav-item"><Link to="/about">How It Works</Link></li>
            </ul>
          </nav>
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
