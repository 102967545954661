import React, { useState, useEffect } from "react";
import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import BooksList from "./BooksList/BooksList";
import HighlightsList from "./HighlightsList/HighlightsList";
import "./NotesContainer.css";

const NotesContainer = ({ db }) => {
  const [books, setBooks] = useState([]);
  const [selectedBook, setSelectedBook] = useState(null);
  const [highlights, setHighlights] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");

  // function to get the books and the last highlight date
  useEffect(() => {
    if (db) {
      const results = db.exec(`
              SELECT DISTINCT c.BookTitle, MAX(b.DateCreated) AS LastHighlightDate
              FROM Bookmark b
              LEFT JOIN content c ON b.VolumeID = c.bookID
              GROUP BY 1
              ORDER BY 2 DESC
            `);
      if (results.length > 0) {
        setBooks(results[0].values.map((row) => row[0]));
        setSelectedBook(results[0].values[0][0]); // Select first book by default
      }
    }
  }, [db]);
  
  // function to get the highlights for a selected book
  useEffect(() => {
    if (db && selectedBook) {
      const results = db.exec(
        `
          SELECT c.BookTitle, c.Attribution, b.Text, b.Annotation, b.DateCreated
          FROM Bookmark b
          LEFT JOIN content c ON b.VolumeID = c.bookID
          WHERE c.BookTitle = ?
          GROUP BY 1,2,3,4
          ORDER BY b.DateCreated ${sortOrder === "asc" ? "ASC" : "DESC"}
        `,
        [selectedBook]
      );
      if (results.length > 0) {
        setHighlights(results[0].values);
      }
    }
  }, [selectedBook, sortOrder]);

   return (
    <div className="notes-container">
        <BooksList books={books} selectedBook={selectedBook} onSelectBook={setSelectedBook} />
        <HighlightsList highlights={highlights} selectedBook={selectedBook} sortOrder={sortOrder} setSortOrder={setSortOrder} />
    </div>
   )

};

export default NotesContainer;