import React from 'react';

const FAQ = () => {
  return (
    <div className="faq-page">
      <h1>Frequently Asked Questions</h1>
      {/* Add your FAQ content here */}
    </div>
  );
};

export default FAQ;