import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <div className="contact-page">
      <h1>Contact Us</h1>
      <p>We'd love to hear from you! If you have any questions, suggestions, or feedback, please don't hesitate to reach out.</p>
      <div className="contact-info">
        <h2>Email</h2>
        <a href="mailto:awks888@outlook.com">awks888@outlook.com</a>
      </div>
    </div>
  );
};

export default Contact;