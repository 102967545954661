import React from 'react';
import './BooksList.css';

function BooksList({ books, selectedBook, onSelectBook }) {
  return (
    <div className="books">
      {books.map((book, index) => (
        <button
          key={index}
          className={`book ${selectedBook === book ? 'active' : ''}`}
          onClick={() => onSelectBook(book)}
        >
          {book}
        </button>
      ))}
    </div>
  );
}

export default BooksList;